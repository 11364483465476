import React from 'react'
import Helmet from 'react-helmet'

import { graphql } from 'gatsby'
import get from 'lodash/get'
import styled from '@emotion/styled'

import Layout from '../components/Layout'

class Press extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const siteKeywords = get(
      this,
      'props.data.site.siteMetadata.siteKeywords'
    )
    const press = get(this, 'props.data.allPressJson.edges')
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription },
          { name: 'keywords', content: siteKeywords}]}
          title={siteTitle}
        />
        <PressContainer>
          <BSContainer>
              <div className="row press-article">
                <div className="col"><h1>In The Media</h1></div>
              </div>
              {press.reverse().map(({ node }) => {
                return (
                  <div className="row press-article" key={node.title}>
                    <div className="col-9">
                      <p>
                        <a className="title" target="_blank" href={ node.url } aria-label={"read " + node.title} rel="noreferrer">{ node.title }</a>
                        <a className="source" target="_blank" href={ node.url } aria-label={"read full piece at" + node.source} rel="noreferrer">{ node.source }</a>
                      </p>
                    </div>
                    <div className="col text-right">
                      <p className="date">{ node.date }</p>
                    </div>
                  </div>
                )
              })}
          </BSContainer>
        </PressContainer>
    </Layout>
    )
  }
}

export default Press

const BSContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`

const PressContainer = styled.div`
  text-align: left;
  padding-top: 20px;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  background-color: #fff;

  @media (min-width: 576px) {
    ${BSContainer} {
        max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    ${BSContainer} {
        max-width: 720px;
    }
  }


  @media (min-width: 992px) {
    ${BSContainer} {
        max-width: 960px;
    }
  }

  @media(min-width: 1200px) {
    ${BSContainer} {
      max-width: 960px;
    }
  }


h1 {
  color: #696969;
  text-transform: none;
}
ul li {
  margin-bottom: 20px;
}

.press-article {
  border-bottom: solid 1px #CCC;
  padding: 40px 0px 20px 0px;
}

a.title {
  font-size: 18px;
  font-weight: 100;
  line-height: 22px;
  color: #696969;
  font-weight: 700;
  box-shadow: none;
  display: block;
  clear:both;
}

.date {
  display: block;
  clear: both;
  font-size: 14px;
  color: #696969;
}

a.source{
  display: block;
  clear: both;
  font-size: 16px;
  box-shadow: none;
  margin-top: 10px;
  color: #1A7B8E;
}

.row {
  justify-content: space-between;
  .col {
    flex: 1;
  }
}
`
const FlexGrid = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteKeywords
      }
    }
    allPressJson {
      edges {
        node {
          title
          source
          date(formatString: "MMMM Do, YYYY")
          url
        }
      }
    }
  }
`
